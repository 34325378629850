import * as React from "react";
import { useState, useRef, useEffect } from "react";
import {
  Grid,
  Box,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  Button,
  Typography,
  TextField,
  FormControlLabel,
  Container,
  Paper,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useDispatch } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";

import useInput from "../../hooks/useInput";
import { AddressInput } from "..";
import { retrieveOrgDataUsingMemberId, updateDocument } from "../../firebase";
import { COLLECTIONS, ROLES } from "../../constants/constants";
import { getNextMemberId } from "../../firebase";
import { useAuth } from "../../hooks";
import { setOpenEditUserDialog } from "../../redux/slices/openEditUserDialogSlice";
import { isNotEmpty } from "../../utils";

export function CoordinatorFormMeeting(props) {
  const org = props.org;
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [memberData, setMemberData] = useState({});
  const [docID, setDocID] = useState(null);
  const [isMember, setIsMember] = useState(
    currentUser?.roles?.includes(ROLES.FIELD)
  );
  const [isCoordinator, setIsCoordinator] = useState(
    currentUser?.roles?.includes(ROLES.COORDINATOR)
  );
  const [coordinatorName, setCoordinatorName] = useState(
    currentUser.firstName + " " + currentUser.lastName
  );

  const dispatch = useDispatch();

  const [isMeetingConducted, setIsMeetingConducted] = useState(false);

  // const handleMemberChange = (event) => {
  //   handleReset();
  //   if (event.target.value === "true") {
  //     setMemberID("");
  //   } else {
  //     setMemberID(JSON.parse(sessionStorage.getItem("memberId")));
  //   }
  //   setIsMember(event.target.value === "true");
  // };

  // const memberIDChangeHandler = async (event) => {
  //   setMemberID(event.target.value);
  // };
  const handleMeetingConductedChange = (event) =>
    setIsMeetingConducted(event.target.value === "true");

  const handleReset = () => {
    resetAreaOfVisitInput("");
    resetDateOfVisitInput(null);
    resetPlannedActivityInput("");
    resetPerformedActivityInput("");
    resetResultInput("");
    resetCommentsInput("");
  };

  // const formRefs = useRef({
  //   addressInputRef: null,
  // });
  const {
    value: areaOfVisit,
    isValid: areaOfVisitIsValid,
    hasError: areaOfVisitInputHasError,
    valueChangeHandler: areaOfVisitChangedHandler,
    inputBlurHandler: areaOfVisitBlurHandler,
    reset: resetAreaOfVisitInput,
  } = useInput(isNotEmpty, memberData?.areaOfVisit);

  const {
    value: lastName,
    isValid: lastNameIsValid,
    hasError: lastNameInputHasError,
    valueChangeHandler: lastNameChangedHandler,
    inputBlurHandler: lastNameBlurHandler,
    reset: resetLastNameInput,
  } = useInput(isNotEmpty, memberData?.lastName);

  const {
    value: dob,
    hasError: dobInputHasError,
    valueChangeHandler: dobChangedHandler,
    reset: resetDobInput,
  } = useInput(() => {}, memberData?.dob);

  let date = new Date();
  date = date.toISOString().slice(0, 10);
  const {
    value: dateOfVisit,
    hasError: dateOfVisitHasError,
    valueChangeHandler: dateOfVisitChangedHandler,
    reset: resetDateOfVisitInput,
  } = useInput(() => {}, memberData?.dateOfVisit || (!isMember && date));

  const {
    value: plannedActivity,
    valueChangeHandler: plannedActivityChangeHandler,
    reset: resetPlannedActivityInput,
  } = useInput(() => {}, memberData?.plannedActivity);

  const {
    value: meetingAgenda,
    valueChangeHandler: meetingAgendaValueChangeHandler,
    reset: resetMeetingAgendaInput,
  } = useInput(() => {}, memberData?.meetingAgenda);

  const {
    value: performedActivity,
    valueChangeHandler: performedActivityChangeHandler,
    reset: resetPerformedActivityInput,
  } = useInput(() => {}, memberData?.performedActivity);

  const {
    value: result,
    valueChangeHandler: resultChangeHandler,
    reset: resetResultInput,
  } = useInput(() => {}, memberData?.result);

  const {
    value: comments,
    valueChangeHandler: commentsChangeHandler,
    reset: resetCommentsInput,
  } = useInput(() => {}, memberData?.comments);
  const {
    value: fieldStaffName,
    valueChangeHandler: staffNameChangeHandler,
    reset: resetStaffNameInput,
  } = useInput(() => {}, memberData?.fieldStaffName);

  let formIsValid = false;

  if (areaOfVisitIsValid) {
    formIsValid = true;
  }

  const formSubmissionHandler = async (event) => {
    event.preventDefault();

    if (!formIsValid) {
      return;
    }

    setIsSubmit(true);
    setLoading(true);

    // const address = formRefs.current.addressInputRef.getAddress();
    const meetingDetails = {
      dateOfVisit,
      areaOfVisit,
      plannedActivity,
      performedActivity,
      result,
      comments,
      // noOfAttendees,
      // meetingAgenda,
      // lastName,
      // dob,
      // address: { ...address },
      // institutionName,
      // ,
      // billNo,
      // refNo,
      // fieldStaffName,
      // isAssociatedUser,
      // aadhar,
      // renewalDate,
      // approved: false,
    };
    try {
      if (isCoordinator) {
        await props.saveData(org, meetingDetails);
        props.showSnackBar("success", "Form submitted sucessfully!");
      } else {
        await updateDocument(org, docID, meetingDetails);
        props.showSnackBar("success", "Form updated successfully!");
      }
    } catch (e) {
      console.error("Error adding document: ", e);
    }

    // const memberId = await getNextMemberId(org);
    // sessionStorage.setItem("memberId", JSON.stringify(memberId));

    event.target.reset();
    handleReset();

    // updateDocument(COLLECTIONS.USER, currentUser.id, {
    //   noOfApplicants: currentUser.noOfApplicants + 1,
    // });

    // dispatch(setOpenEditUserDialog(false));
    setLoading(false);
    setIsSubmit(false);
  };

  // useEffect(() => {
  //   const getNextMemberIdFunction = async () => {
  //     const memberId = await getNextMemberId(org);
  //     sessionStorage.setItem("memberId", JSON.stringify(memberId));
  //     setMemberID(memberId);
  //   };
  //   if (!memberID) {
  //     getNextMemberIdFunction();
  //   }
  // }, []);

  // useEffect(() => {
  //   let interval;
  //   if (memberID && isMember) {
  //     setLoading(true);

  //     interval = setTimeout(async () => {
  //       try {
  //         retrieveOrgDataUsingMemberId(COLLECTIONS.SNEHIDHI, memberID).then(
  //           (response) => {
  //             if (!response) {
  //               props.showSnackBar("error", "No member dound!");
  //             }
  //             formRefs.current.addressInputRef.setAddress(response?.address);
  //             setDocID(response?.id);
  //             setIsAssociatedUser(response?.isAssociatedUser);
  //             setMemberData(response);
  //             setLoading(false);
  //           }
  //         );
  //       } catch (error) {
  //         props.showSnackBar("error", "No member dound!");
  //       }
  //     }, 2000);
  //   }
  //   return () => clearInterval(interval);
  // }, [memberID, isMember]);

  return (
    <Container
      component="main"
      maxWidth="md"
      sx={{ width: "100%" }}
      style={{ opacity: loading && `0.7` }}
    >
      <Paper sx={{ my: { xs: 3, md: 3 }, p: { xs: 2, md: 3 } }}>
        <Typography
          component="h4"
          variant="h4"
          align="center"
          paddingBottom={3}
        >
          {props.org.toUpperCase().replace("_", " ")}
        </Typography>
        <form onSubmit={formSubmissionHandler}>
          <Grid container spacing={3}>
            {/* {props.showHeader ? (
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormLabel id="member-radio-group">
                    Is user already a member?
                  </FormLabel>
                  <RadioGroup
                    aria-label="member"
                    value={isMember}
                    onChange={handleMemberChange}
                  >
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No, not a member"
                    />
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes, already a member"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            ) : (
              <Box sx={{ m: 2 }} />
            )} */}
            <Grid item xs={12} container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  required
                  id="Coordinator Name"
                  label="Coordinator Name"
                  fullWidth
                  disabled={isCoordinator}
                  error={!isNotEmpty}
                  helperText={!isNotEmpty && "This field cannot be empty"}
                  // onChange={memberIDChangeHandler}
                  value={coordinatorName}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="date"
                  fullWidth
                  id="date"
                  label="Date of Visit"
                  onChange={dateOfVisitChangedHandler}
                  inputProps={{
                    max: new Date().toISOString().slice(0, 10),
                  }}
                  value={dateOfVisit}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  type="text"
                  id="areaOfVisit"
                  label="Area of Visit"
                  onChange={areaOfVisitChangedHandler}
                  error={areaOfVisitInputHasError}
                  helperText={
                    areaOfVisitInputHasError && "This field cannot be empty"
                  }
                  onBlur={areaOfVisitBlurHandler}
                  value={areaOfVisit}
                  // value={renewalDate}
                  // InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    type="text"
                    id="plannedActivity"
                    label="Planned Activity"
                    fullWidth
                    value={plannedActivity}
                    onChange={plannedActivityChangeHandler}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    type="text"
                    id="performedActivity"
                    label="Performed Activity"
                    fullWidth
                    value={performedActivity}
                    onChange={performedActivityChangeHandler}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Result"
                    fullWidth
                    multiline
                    rows={4}
                    value={result}
                    onChange={resultChangeHandler}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Comments"
                    fullWidth
                    multiline
                    rows={4}
                    value={comments}
                    onChange={commentsChangeHandler}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              type="submit"
              disabled={!formIsValid || isSubmit}
              variant="contained"
              sx={{ mt: 3, ml: 1 }}
            >
              Submit
            </Button>
          </Box>
        </form>
        {loading && (
          <CircularProgress
            size={100}
            sx={{
              color: green[500],
              margin: "auto",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              position: "fixed",
            }}
          />
        )}
      </Paper>
    </Container>
  );
}
