import { createSlice } from "@reduxjs/toolkit";

export const openEditCoordinatorDialogSlice = createSlice({
  name: "openEditCoordinatorDialog",
  initialState: {
    value: false,
  },
  reducers: {
    setOpenEditCoordinatorDialog: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setOpenEditCoordinatorDialog } =
  openEditCoordinatorDialogSlice.actions;

export default openEditCoordinatorDialogSlice.reducer;
