export const adminDrawerWidth = 280;

export const COLLECTIONS = {
  USER: "user",
  MAITHRI: "maithri",
  MANUSHI: "manushi",
  SNEHIDHI: "snehidhi",
  MEMBER_SEQ: "memberseq",
  MEETING: "meeting",
  HOUSEVISIT: "house_visit",
  COORDINATORMEETING: "coordinator_meeting",
};

export const ROLES = {
  ADMIN: "admin",
  FIELD: "field",
  COORDINATOR: "coordinator",
};

export const usersListGridOrder = {
  snehidhi: [
    { key: "action", headerName: "Action" },
    { key: "memberID", headerName: "Member ID" },
    { key: "firstName", headerName: "First Name" },
    { key: "lastName", headerName: "Last Name" },
    { key: "dob", headerName: "Date Of Birth" },
    { key: "renewalDate", headerName: "Renewal Date" },
    { key: "address", headerName: "Address" },
    { key: "institutionName", headerName: "Institution Name" },
    { key: "courseName", headerName: "Course Name" },
    { key: "billNo", headerName: "Bill Number" },
    { key: "refNo", headerName: "Reference Number" },
    { key: "fieldStaffName", headerName: "Field Staff Name" },
  ],
  manushi: [
    { key: "action", headerName: "Action" },
    { key: "memberID", headerName: "Member ID" },
    { key: "firstName", headerName: "First Name" },
    { key: "lastName", headerName: "Last Name" },
    { key: "dob", headerName: "Date Of Birth" },
    { key: "renewalDate", headerName: "Renewal Date" },
    { key: "address", headerName: "Address" },
    { key: "phone", headerName: "Phone Number" },
    { key: "aadhar", headerName: "Aadhar Number" },
    { key: "billNo", headerName: "Bill Number" },
    { key: "refNo", headerName: "Reference Number" },
    { key: "fieldStaffName", headerName: "Field Staff Name" },
    { key: "dependants", headerName: "Dependants" },
  ],
  maithri: [
    { key: "action", headerName: "Action" },
    { key: "memberID", headerName: "Member ID" },
    { key: "firstName", headerName: "First Name" },
    { key: "lastName", headerName: "Last Name" },
    { key: "dob", headerName: "Date Of Birth" },
    { key: "renewalDate", headerName: "Renewal Date" },
    { key: "address", headerName: "Address" },
    { key: "phone", headerName: "Phone Number" },
    { key: "aadhar", headerName: "Aadhar Number" },
    { key: "billNo", headerName: "Bill Number" },
    { key: "refNo", headerName: "Reference Number" },
    { key: "fieldStaffName", headerName: "Field Staff Name" },
    { key: "dependants", headerName: "Dependants" },
  ],
  coordinator_meeting: [
    { key: "areaOfVisit", headerName: "Area of Visit" },
    { key: "comments", headerName: "Comments" },
    { key: "dateOfVisit", headerName: "Date of Visit" },
    { key: "performedActivity", headerName: "Performed Activity" },
    { key: "plannedActivity", headerName: "Planned Activity" },
    { key: "result", headerName: "Result" },
  ],
  meeting: [
    { key: "fieldWorkerName", headerName: "Field Worker Name" },
    { key: "noOfAttendees", headerName: "Number of Attendees" },
    { key: "isMeetingConducted", headerName: "Meeting Conducted" },
    { key: "areaOfVisit", headerName: "Area of Visit" },
    { key: "meetingAgenda", headerName: "Meeting Agenda" },
  ],
  house_visit: [
    { key: "product", headerName: "Product" },
    { key: "areaOfVisit", headerName: "Area of Visit" },
    { key: "noOfPeopleMet", headerName: "Number of People Met" },
    { key: "product", headerName: "Product" },
  ],
};
