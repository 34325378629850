import * as React from "react";
import { useState, useRef, useEffect } from "react";
import {
  Grid,
  Box,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  Button,
  Typography,
  TextField,
  FormControlLabel,
  Container,
  Paper,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useDispatch } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";

import useInput from "../../hooks/useInput";
import { AddressInput } from "..";
import { retrieveOrgDataUsingMemberId, updateDocument } from "../../firebase";
import { COLLECTIONS, ROLES } from "../../constants/constants";
import { getNextMemberId } from "../../firebase";
import { useAuth } from "../../hooks";
import { setOpenEditUserDialog } from "../../redux/slices/openEditUserDialogSlice";
import { isNotEmpty } from "../../utils";

export function FieldWorkerFormHouseVisit(props) {
  const org = props.org;
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [memberData, setMemberData] = useState({});
  const [docID, setDocID] = useState(null);
  const [isMember, setIsMember] = useState(!!props?.memberID);
  const [memberID, setMemberID] = useState(props.memberID);
  const [isFieldWorker, setIsFieldWorker] = useState(
    currentUser?.roles?.includes(ROLES.FIELD)
  );

  const dispatch = useDispatch();

  const [product, setProduct] = useState("Maithri");

  const handleMemberChange = (event) => {
    handleReset();
    if (event.target.value === "true") {
      setMemberID("");
    } else {
      setMemberID(JSON.parse(sessionStorage.getItem("memberId")));
    }
    setIsMember(event.target.value === "true");
  };

  const memberIDChangeHandler = async (event) => {
    setMemberID(event.target.value);
  };
  const handleProductChange = (event) => setProduct(event.target.value);

  const handleReset = () => {
    resetAreaOfVisitInput("");
    resetDateOfVisitInput(null);
    resetNoOfPeopleMetInput();
    setProduct("Maithri");
  };

  const formRefs = useRef({
    addressInputRef: null,
  });
  const {
    value: noOfPeopleMet,
    isValid: noOfPeopleMetIsValid,
    hasError: noOfPeopleMetInputHasError,
    valueChangeHandler: noOfPeopleMetChangedHandler,
    inputBlurHandler: noOfPeopleMetBlurHandler,
    reset: resetNoOfPeopleMetInput,
  } = useInput(isNotEmpty, memberData?.noOfPeopleMet);

  const {
    value: areaOfVisit,
    isValid: areaOfVisitIsValid,
    hasError: areaOfVisitInputHasError,
    valueChangeHandler: areaOfVisitChangedHandler,
    inputBlurHandler: areaOfVisitBlurHandler,
    reset: resetAreaOfVisitInput,
  } = useInput(isNotEmpty, memberData?.areaOfVisit);

  const {
    value: dateOfVisit,
    hasError: dateOfVisitInputHasError,
    valueChangeHandler: dateOfVisitChangedHandler,
    reset: resetDateOfVisitInput,
  } = useInput(() => {}, memberData?.dateOfVisit);

  const {
    value: nameOfTheWoman,
    hasError: nameOfTheWomanInputHasError,
    valueChangeHandler: nameOfTheWomanChangedHandler,
    reset: resetNameOfTheWomanInput,
  } = useInput(() => {}, memberData?.nameOfTheWoman);

  let date = new Date();
  date.setFullYear(date.getFullYear() + 1);
  date = date.toISOString().slice(0, 10);
  const {
    value: renewalDate,
    hasError: renewalDateHasError,
    valueChangeHandler: renewalDateChangedHandler,
    reset: resetRenewalDateInput,
  } = useInput(() => {}, memberData?.renewalDate || (!isMember && date));

  const {
    value: aadhar,
    valueChangeHandler: aadharChangeHandler,
    reset: resetAadharInput,
  } = useInput(() => {}, memberData?.aadhar);

  const {
    value: institutionName,
    valueChangeHandler: institutionValueChangeHandler,
    reset: resetInsitutionInput,
  } = useInput(() => {}, memberData?.institutionName);

  const {
    value: courseName,
    valueChangeHandler: courseChangeHandler,
    reset: resetCourseInput,
  } = useInput(() => {}, memberData?.courseName);

  const {
    value: billNo,
    valueChangeHandler: billChangeHandler,
    reset: resetBillNoInput,
  } = useInput(() => {}, memberData?.billNo);

  const {
    value: refNo,
    valueChangeHandler: refChangeHandler,
    reset: resetRefNoInput,
  } = useInput(() => {}, memberData?.refNo);
  const {
    value: fieldStaffName,
    valueChangeHandler: staffNameChangeHandler,
    reset: resetStaffNameInput,
  } = useInput(() => {}, memberData?.fieldStaffName);

  let formIsValid = false;

  if (areaOfVisitIsValid && noOfPeopleMetIsValid) {
    formIsValid = true;
  }

  const formSubmissionHandler = async (event) => {
    event.preventDefault();

    if (!formIsValid) {
      return;
    }

    setIsSubmit(true);
    setLoading(true);

    // const address = formRefs.current.addressInputRef.getAddress();
    const memberDetails = {
      areaOfVisit,
      dateOfVisit,
      product,
      noOfPeopleMet,
    };
    try {
      if (isFieldWorker) {
        await props.saveData(org, memberDetails);
        props.showSnackBar("success", "Form submitted sucessfully!");
      } else {
        await updateDocument(org, docID, memberDetails);
        props.showSnackBar("success", "Form updated successfully!");
      }
    } catch (e) {
      console.error("Error adding document: ", e);
    }

    // const memberId = await getNextMemberId(org);
    // sessionStorage.setItem("memberId", JSON.stringify(memberId));

    event.target.reset();
    handleReset();

    // updateDocument(COLLECTIONS.USER, currentUser.id, {
    //   noOfApplicants: currentUser.noOfApplicants + 1,
    // });

    // dispatch(setOpenEditUserDialog(false));
    setLoading(false);
    setIsSubmit(false);
  };

  useEffect(() => {
    const getNextMemberIdFunction = async () => {
      const memberId = await getNextMemberId(org);
      sessionStorage.setItem("memberId", JSON.stringify(memberId));
      setMemberID(memberId);
    };
    if (!memberID) {
      getNextMemberIdFunction();
    }
  }, []);

  useEffect(() => {
    let interval;
    if (memberID && isMember) {
      setLoading(true);

      interval = setTimeout(async () => {
        try {
          retrieveOrgDataUsingMemberId(COLLECTIONS.SNEHIDHI, memberID).then(
            (response) => {
              if (!response) {
                props.showSnackBar("error", "No member dound!");
              }
              formRefs.current.addressInputRef.setAddress(response?.address);
              setDocID(response?.id);
              // setIsAssociatedUser(response?.isAssociatedUser);
              setMemberData(response);
              setLoading(false);
            }
          );
        } catch (error) {
          props.showSnackBar("error", "No member dound!");
        }
      }, 2000);
    }
    return () => clearInterval(interval);
  }, [memberID, isMember]);

  return (
    <Container
      component="main"
      maxWidth="md"
      sx={{ width: "100%" }}
      style={{ opacity: loading && `0.7` }}
    >
      <Paper sx={{ my: { xs: 3, md: 3 }, p: { xs: 2, md: 3 } }}>
        <Typography
          component="h4"
          variant="h4"
          align="center"
          paddingBottom={3}
        >
          {props.org.toUpperCase().replace("_", " ")}
        </Typography>
        <form onSubmit={formSubmissionHandler}>
          {/* <Grid container spacing={3}> */}
          {/* {props.showHeader ? (
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormLabel id="member-radio-group">
                    Is user already a member?
                  </FormLabel>
                  <RadioGroup
                    aria-label="member"
                    value={isMember}
                    onChange={handleMemberChange}
                  >
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No, not a member"
                    />
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes, already a member"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            ) : (
              <Box sx={{ m: 2 }} />
            )} */}
          <Grid item xs={12} container spacing={3}>
            {/* <Grid item xs={6}>
                <TextField
                  required
                  id="memberID"
                  label=""
                  fullWidth
                  disabled={!isMember}
                  error={!isNotEmpty}
                  helperText={!isNotEmpty && "This field cannot be empty"}
                  onChange={memberIDChangeHandler}
                  value={memberID}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="date"
                  id="date"
                  label="Next Date of Renewal"
                  onChange={renewalDateChangedHandler}
                  inputProps={{
                    min: new Date().toISOString().slice(0, 10),
                  }}
                  value={renewalDate}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid> */}
            {/* <Grid item xs={12} sm={6}>
              <TextField
                required
                id="firstName"
                label="First name"
                fullWidth
                autoComplete="given-name"
                error={firstNameInputHasError}
                helperText={
                  firstNameInputHasError && "This field cannot be empty"
                }
                onChange={firstNameChangedHandler}
                onBlur={firstNameBlurHandler}
                value={firstName}
                />
              </Grid> */}
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel id="employee-radio-group">
                  For which product was the visit conducted?
                </FormLabel>
                <RadioGroup
                  aria-label="employee"
                  value={product}
                  onChange={handleProductChange}
                >
                  <FormControlLabel
                    value="Maithri"
                    control={<Radio />}
                    label="Maithri"
                  />
                  <FormControlLabel
                    value="Manushi"
                    control={<Radio />}
                    label="Manushi"
                  />
                  <FormControlLabel
                    value="Snehidhi"
                    control={<Radio />}
                    label="Snehidhi"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                type="text"
                id="nameOfTheWoman"
                label="Name of the Woman / Girl Mobile Number"
                fullWidth
                value={nameOfTheWoman}
                onChange={nameOfTheWomanChangedHandler}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="areaOfVisit"
                label="Area of Visit"
                fullWidth
                autoComplete="areaOfVisit"
                error={areaOfVisitInputHasError}
                helperText={
                  areaOfVisitInputHasError && "This field cannot be empty"
                }
                onChange={areaOfVisitChangedHandler}
                onBlur={areaOfVisitBlurHandler}
                value={areaOfVisit}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                type="number"
                id="noOfPeopleMet"
                fullWidth
                required
                label="Number of People Met"
                error={noOfPeopleMetInputHasError}
                helperText={
                  noOfPeopleMetInputHasError && "This field cannot be empty"
                }
                onChange={noOfPeopleMetChangedHandler}
                onBlur={noOfPeopleMetBlurHandler}
                value={noOfPeopleMet}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="date"
                label="Date of Visit"
                onChange={dateOfVisitChangedHandler}
                value={dateOfVisit}
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  max: new Date().toISOString().slice(0, 10),
                }}
              />
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              type="submit"
              disabled={!formIsValid || isSubmit}
              variant="contained"
              sx={{ mt: 3, ml: 1 }}
            >
              Submit
            </Button>
          </Box>
        </form>
        {loading && (
          <CircularProgress
            size={100}
            sx={{
              color: green[500],
              margin: "auto",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              position: "fixed",
            }}
          />
        )}
      </Paper>
    </Container>
  );
}
