import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import {
  PieChart,
  Pie,
  ResponsiveContainer,
  Tooltip,
  Cell,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  Bar,
  Line,
  ComposedChart,
} from "recharts";
import { useState, useRef, useEffect } from "react";
import { retrieveDocsCount } from "../../firebase";
import { COLLECTIONS } from "../../constants/constants";
import { Typography } from "@mui/material";

export function Statistics() {
  const [data01, setData01] = React.useState([]);
  const [data02, setData02] = React.useState([]);

  useEffect(() => {
    const cnt = async () => {
      console.log("in count");

      const maithriCount = await retrieveDocsCount(COLLECTIONS.MAITHRI);
      const manushiCount = await retrieveDocsCount(COLLECTIONS.MANUSHI);
      const snehidhiCount = await retrieveDocsCount(COLLECTIONS.SNEHIDHI);

      const meetingCount = await retrieveDocsCount(COLLECTIONS.MEETING);
      const coordinatorMeetingCount = await retrieveDocsCount(
        COLLECTIONS.COORDINATORMEETING
      );
      const houseVisitsCount = await retrieveDocsCount(COLLECTIONS.HOUSEVISIT);

      setData01([
        { name: COLLECTIONS.MANUSHI.toUpperCase(), value: manushiCount },
        { name: COLLECTIONS.MAITHRI.toUpperCase(), value: maithriCount },
        { name: COLLECTIONS.SNEHIDHI.toUpperCase(), value: snehidhiCount },
      ]);

      setData02([
        { name: COLLECTIONS.MEETING.toUpperCase(), value: meetingCount },
        {
          name: COLLECTIONS.COORDINATORMEETING.toUpperCase().replace("_", " "),
          value: coordinatorMeetingCount,
        },
        {
          name: COLLECTIONS.HOUSEVISIT.toUpperCase().replace("_", " "),
          value: houseVisitsCount,
        },
      ]);

      console.log("out count");
    };
    cnt();
  }, []);

  const COLORS = ["#FF8042", "#0088FE", "#00C49F"];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${data01[index].name}: ${data01[index].value}`}

        {/* {`${(percent * 100).toFixed(0)}%`} */}
      </text>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "2em",
        flexWrap: "wrap",
        "& > :not(style)": {
          m: 1,
          width: 600,
          height: 500,
        },
      }}
    >
      <Paper elevation={0}>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart width={300} height={300}>
            <text
              x={600 / 2}
              y={20}
              fill="black"
              textAnchor="middle"
              dominantBaseline="central"
            >
              <tspan fontSize="16">Number of Users</tspan>
            </text>
            <Pie
              data={data01}
              dataKey="value"
              cx="50%"
              cy="52%"
              fill="#82ca9d"
              labelLine={false}
              label={renderCustomizedLabel}
            >
              {data01.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </Paper>
      <Paper>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={600}
            height={300}
            data={data02}
            style={{
              backgroundColor: "#fff",
            }}
            margin={{
              top: 60,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <text
              x={600 / 2}
              y={20}
              fill="black"
              textAnchor="middle"
              dominantBaseline="central"
            >
              <tspan fontSize="16">Number of Events</tspan>
            </text>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="value" stackId="a" fill="#8dd1e1" barSize={40} min={5} />
          </BarChart>
        </ResponsiveContainer>
      </Paper>
    </Box>
  );
}
