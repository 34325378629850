import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CssBaseline from "@mui/material/CssBaseline";

import { Box } from "@mui/material";
import "./Home.scss";
import { Header } from "../../components";
import { COLLECTIONS, ROLES } from "../../constants/constants";
import { useAuth } from "../../hooks";

export const Home = () => {
  const { currentUser } = useAuth();

  const images = [
    {
      src: require("../../images/maithri.jpeg"),
      alt: COLLECTIONS.MAITHRI,
      title: COLLECTIONS.MAITHRI,
      link: `/fieldworkerform?org=${COLLECTIONS.MAITHRI}`,
    },
    {
      src: require("../../images/manushi.jpeg"),
      alt: COLLECTIONS.MANUSHI,
      title: COLLECTIONS.MANUSHI,
      link: `/fieldworkerform?org=${COLLECTIONS.MANUSHI}`,
    },
    {
      src: require("../../images/snehidhi.jpg"),
      alt: COLLECTIONS.SNEHIDHI,
      title: COLLECTIONS.SNEHIDHI,
      link: `/fieldworkerform?org=${COLLECTIONS.SNEHIDHI}`,
    },
    {
      src: require("../../images/meeting.jpeg"),
      alt: COLLECTIONS.MEETING,
      title: COLLECTIONS.MEETING,
      link: `/fieldworkerform?org=${COLLECTIONS.MEETING}`,
    },
    {
      src: require("../../images/housevisit.jpg"),
      alt: COLLECTIONS.HOUSEVISIT,
      title: COLLECTIONS.HOUSEVISIT,
      link: `/fieldworkerform?org=${COLLECTIONS.HOUSEVISIT}`,
    },
  ];

  const coordinatorImages = [
    {
      src: require("../../images/meeting.jpeg"),
      alt: COLLECTIONS.COORDINATORMEETING,
      title: COLLECTIONS.COORDINATORMEETING,
      link: `/coordinatorform?org=${COLLECTIONS.COORDINATORMEETING}`,
    },
  ];

  const renderCards = () => {
    if (currentUser?.roles?.includes(ROLES.ADMIN)) {
      return [...images, ...coordinatorImages].map((image, index) => (
        <div class="first card" key={index}>
          <img class="card-img" src={image.src} alt="" />
          <div class="card-description-bk"></div>
          <div class="card-description">
            <p>{image.title.toUpperCase().replace("_", " ")}</p>
          </div>
          <div class="card-btn">
            <a href={image.link}>
              <ArrowForwardIcon fontSize="medium" />
            </a>
          </div>
        </div>
      ));
    } else if (currentUser?.roles?.includes(ROLES.FIELD)) {
      return images.map((image, index) => (
        <div class="first card" key={index}>
          <img class="card-img" src={image.src} alt="" />
          <div class="card-description-bk"></div>
          <div class="card-description">
            <p>{image.title.toUpperCase()}</p>
          </div>
          <div class="card-btn">
            <a href={image.link}>
              <ArrowForwardIcon fontSize="medium" />
            </a>
          </div>
        </div>
      ));
    } else if (currentUser?.roles?.includes(ROLES.COORDINATOR)) {
      return coordinatorImages.map((image, index) => (
        <div class="first card" key={index}>
          <img class="card-img" src={image.src} alt="" />
          <div class="card-description-bk"></div>
          <div class="card-description">
            <p>{image.title.toUpperCase()}</p>
          </div>
          <div class="card-btn">
            <a href={image.link}>
              <ArrowForwardIcon fontSize="medium" />
            </a>
          </div>
        </div>
      ));
    }
  };

  return (
    <div className="container">
      <CssBaseline />

      <Header />
      <div className="contentBody">
        <Box sx={{ padding: "1rem" }}>
          <Typography
            variant="h5"
            noWrap
            title="Navigate to home"
            style={{
              // color: "black",
              justifyContent: "center",
              display: "flex",
              color: "white",
            }}
          >
            SELECT A PROGRAM TO ENROLL USERS OR FILL DETAILS
          </Typography>
        </Box>
        <Grid className="overallContainer">{renderCards()}</Grid>
      </div>
    </div>
  );
};
